<template>
  <div class="pages">
    <h1>Criar novo banner</h1>
    <button class="btn outline back-btn" v-on:click="$router.back()">Voltar</button>
    <div class="wrapper">
      <div>
        <label for="sort">Ordem do banner <small>(nro menor aparece primeiro)</small></label>
        <input type="number" id="sort" v-model="item.sort" placeholder="Ordem">
      </div>
      <div>
        <label for="title">Título do banner</label>
        <input type="text" id="title" v-model="item.title" placeholder="Título">
      </div>
      <div>
        <label for="link">Link do banner</label>
        <input type="text" id="link" v-model="item.link" placeholder="Link">
      </div>
      <div>
        <label for="imgDsktp">Banner Desktop</label>
        <input type="file" id="imgDsktp" v-on:change="imgDesktopChanged" accept="image/*" ref="imgdesk">
        <p v-if="savingDesktop">Fazendo upload...</p>
        <img v-if="item.imageDesktop" :src="$mediaBaseUrl + item.imageDesktop" :alt="item.title">
      </div>
      <div>
        <label for="imgMbl">Banner Mobile</label>
        <input type="file" id="imgMbl" v-on:change="imgMobileChanged" accept="image/*" ref="imgmob">
        <p v-if="savingMobile">Fazendo upload...</p>
        <img v-if="item.imageMobile" :src="$mediaBaseUrl + item.imageMobile" :alt="item.title">
      </div>
      <br>
      <button class="btn save-btn" v-on:click="saveItem" :disabled="loading">{{ loading ? 'Salvando...' : 'Salvar' }}</button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
const axios = require('axios').default;

export default {
  name: 'newitem',
  data() {
    return {
      loading: false,
      savingDesktop: false,
      savingMobile: false,
      item: {
        sort: 0,
        title: '',
        link: '',
        imageDesktop: '',
        imageMobile: ''
      }
    }
  },
  methods: {
    saveItem() {
      let error = false;

      if (!error) {
        this.loading = true;
        let self = this;

        axios.post(this.$apiUrl + 'banners/create', this.item)
        .then(response => {
          self.loading = false;
          this.$alert(response.data.message, 'Sucesso', 'success').then(() => {
            self.$router.push('/banner');
          });
        })
        .catch(error => {
          this.$alert(error.response.data.message, 'Erro', 'error');
          console.log(error.response.data.error);
          self.loading = false;
        });
      }
    },
    async imgDesktopChanged() {
      let self = this;
      let file = this.$refs.imgdesk.files[0];
      self.savingDesktop = true;

      if (file) {
        const formData = new FormData();
        formData.append('file', file);

        axios.post(this.$apiUrl + 'files/upload', formData)
                .then(response => {
                  self.item.imageDesktop = response.data.file.key;
                  self.savingDesktop = false;
                })
                .catch(error => {
                  console.log(error);
                  self.savingDesktop = false;
                });
      }
    },
    async imgMobileChanged() {
      let self = this;
      let file = this.$refs.imgmob.files[0];
      self.savingMobile = true;

      if (file) {
        const formData = new FormData();
        formData.append('file', file);

        axios.post(this.$apiUrl + 'files/upload', formData)
                .then(response => {
                  self.item.imageMobile = response.data.file.key;
                  self.savingMobile = false;
                })
                .catch(error => {
                  console.log(error);
                  self.savingMobile = false;
                });
      }
    },
    formatURL() {
      this.page.url = this.page.url.replace(/\s+/g, '-').toLowerCase();
    }
  },
}
</script>

<style scoped lang="scss">
  @import "../../assets/scss/global";
  @import "../../assets/scss/buttons";

  .pages {
    height: 100vh;
    overflow-y: scroll;
    padding: 20px;
    position: relative;

    .back-btn {
      position: absolute;
      top: 20px;
      left: 20px;
      border-radius: 10px;
    }

    .wrapper {
      padding: 50px 200px;
      @media screen and (max-width: 800px) {
        padding: 50px;
      }
    }

    label {
      display: block;
      text-align: left;

      &:nth-of-type(1) {
        margin-top: 10px;
      }
    }

    input, select {
      padding: 10px;
      width: 100%;
      border: 1px solid $primary;
      border-radius: 10px;
      font-size: 1rem;
    }

    select {
      -webkit-appearance: none;
    }

    .save-btn {
      border-radius: 10px;
    }

    img {
      max-width: 50%;
      height: auto;
    }
  }
</style>
